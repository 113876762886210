import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import attention from '../../assets/attention.svg';

export default function Error({ errorTitle, errorMessage }) {
    const { state } = useLocation();
    const errorTitleText = state?.errorTitle || errorTitle;
    const errorMessageText = state?.errorMsg || errorMessage;

    return (
        <div className="tw-min-h-screen tw-flex tw-flex-row tw-justify-center tw-items-center">
            <div className="tw-bg-white tw-flex tw-flex-col sm:tw-max-w-50vw lg:tw-max-w-35vw tw-border-solid tw-rounded tw-border tw-border-grey-1 tw-p-7">
                <div className="sm:tw-justify-start sm:tw-justify-left tw-flex tw-flex-row  tw-flex-1 tw-pt-2">
                    <div className="tw-flex-1">
                        <img className="tw-pr-2" src={attention} alt=""></img>
                    </div>
                    <div className="tw-flex-1 tw-items-center tw-justify-center">
                        <h5 className="error-title tw-font-bold tw-text-center sm:tw-text-left">
                            { errorTitleText }
                        </h5>
                    </div>
                </div>
                <div className="error-msg tw-mt-2">
                    { errorMessageText }
                </div>
            </div>
        </div>
    );
}

Error.propTypes = {
    errorTitle: PropTypes.string,
    errorMessage: PropTypes.string,
};

Error.defaultProps = {
    errorTitle: 'Error',
    errorMessage: 'Please try again later',
};
