import { gql } from '@apollo/client';

export const CREATE_DIRECT_UPLOADS = gql`
    mutation($directUploads: [DirectUploadInput!]!) {
        createDirectUploads(directUploads: $directUploads) {
            directUploads {
                url
                headers
                blobId
                signedBlobId
            }
        }
    }
`;

export const ATTACH_BLOBS = gql`
    mutation($attachBlobs: [AttachBlobInput!]!) {
        attachBlobs(attachBlobs: $attachBlobs) {
            id
            order {
                id
            }
        }
    }
`;

export const CREATE_DIRECT_UPLOADS_CREDIT_APPRAISAL = gql`
    mutation($directUploads: [DirectUploadInputCreditAppraisal!]!) {
        createDirectUploadsCreditAppraisal(directUploads: $directUploads) {
            directUploads {
                url
                headers
                blobId
                signedBlobId
            }
        }
    }
`;

export const ATTACH_BLOBS_CREDIT_APPRAISAL = gql`
    mutation($attachBlobs: [AttachBlobInputCreditAppraisal!]!) {
        attachBlobsCreditAppraisal(attachBlobs: $attachBlobs) {
            id
        }
    }
`;
