export function performUpload(file, headers, url) {
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'PUT',
            headers,
            body: file,
            credentials: 'include',
        }).then((response) => resolve(response))
            .catch((error) => reject(error));
    });
}
