/* eslint-disable react/no-unstable-nested-components */
import React, {
    useEffect,
    useState,
    useCallback,
    useReducer,
    useContext,
    useMemo,
} from 'react';

import {
    Route,
    Switch,
    Redirect,
    useRouteMatch,
    useLocation,
    useHistory,
} from 'react-router-dom';

import * as FullStory from '@fullstory/browser';
import { useIntercom } from 'react-use-intercom';

import {
    useLazyQuery,
    useQuery,
    useMutation,
} from '@apollo/client';

import loadable from '@loadable/component';

import { get, union } from 'lodash';

import Button from '../../components/Button';
import Divider from '../../components/Divider';

import {
    GET_CONTACT,
    UPDATE_CONTACT,
    UPDATE_PERSONAL_GUARANTEE,
    SEND_APPLICATION,
    GET_CONTACT_VERIFICATION_STATUS,
    UPDATE_CONTACT_VERIFICATION_STATUS,
} from '../../graphql/queries/contact';

import {
    GET_DEFAULT_PAYMENT_METHOD,
    UPSERT_PAYMENT_METHOD,
} from '../../graphql/queries/payment';

import {
    GET_BUSINESS_DETAILS,
    UPDATE_BUSINESS_DETAILS,
} from '../../graphql/queries/business';

import {
    GET_ORDER_DETAILS,
    GET_ORDER_SUMMARY,
    GET_SIGNATURE_URL,
    SAVE_AGREEMENT,
    GET_ORDER_META_DATA,
    UPDATE_ORDER_APPLICATION_STEPS,
    FIRST_OPENED_AT,
    UPDATE_PAYMENT_TYPE,
    UPDATE_PAYMENT_TERM,
} from '../../graphql/queries/order';

import { FlowSettersContext, FlowGettersContext } from '../../utils/contexts';

import { useDirectUploadFiles } from '../../utils/hooks';
import { formatCapital, getPaymentInterval } from '../../utils/helpers';

import greyPoint from '../../assets/gray-point.svg';
import bluePoint from '../../assets/blue-point.svg';
import blueTick from '../../assets/blue-tick.svg';
import poweredBy from '../../assets/powered-by.png';
// import check from '../../assets/check.svg';
// import information from '../../assets/information.svg';

import Loader from '../../components/Loader';

const PaymentType = loadable(() => import('./PaymentType'), {
    fallback: <Loader isLoading className="tw-w-20 tw-h-20 tw-mt-6" />,
});
const PersonalDetails = loadable(() => import('../common/PersonalDetails'), {
    fallback: <Loader isLoading className="tw-w-20 tw-h-20 tw-mt-6" />,
});
const BusinessDetails = loadable(() => import('../common/BusinessDetails'), {
    fallback: <Loader isLoading className="tw-w-20 tw-h-20 tw-mt-6" />,
});
const PersonalGuarantee = loadable(() => import('./PersonalGuarantee'), {
    fallback: <Loader isLoading className="tw-w-20 tw-h-20 tw-mt-6" />,
});
const SendApplication = loadable(() => import('./SendApplication'), {
    fallback: <Loader isLoading className="tw-w-20 tw-h-20 tw-mt-6" />,
});
const FinancialDocuments = loadable(() => import('../common/FinancialDocuments'), {
    fallback: <Loader isLoading className="tw-w-20 tw-h-20 tw-mt-6" />,
});
const PaymentMethod = loadable(() => import('./PaymentMethod'), {
    fallback: <Loader isLoading className="tw-w-20 tw-h-20 tw-mt-6" />,
});
const OrderDetails = loadable(() => import('./OrderDetails'), {
    fallback: <Loader isLoading className="tw-w-20 tw-h-20 tw-mt-6" />,
});
const MobileOrderDetails = loadable(() => import('./OrderDetails/Mobile'), {
    fallback: <Loader isLoading className="tw-w-20 tw-h-20 tw-mt-6" />,
});
const PaymentTerms = loadable(() => import('./PaymentTerms'), {
    fallback: <Loader isLoading className="tw-w-20 tw-h-20 tw-mt-6" />,
});
const Verification = loadable(() => import('./Verification'), {
    fallback: <Loader isLoading className="tw-w-20 tw-h-20 tw-mt-6" />,
});
const Agreement = loadable(() => import('./Agreement'), {
    fallback: <Loader isLoading className="tw-w-20 tw-h-20 tw-mt-6" />,
});
const ThankYou = loadable(() => import('./ThankYou'), {
    fallback: <Loader isLoading className="tw-w-20 tw-h-20 tw-mt-6" />,
});

function getPathID(pathname = '') {
    let id = '';
    if (pathname.includes('payment-type')) {
        id = 'payment_type';
    } else if (pathname.includes('personal-details')) {
        id = 'personal_details';
    } else if (pathname.includes('business-details')) {
        id = 'business_details';
    } else if (pathname.includes('financial-documents')) {
        id = 'financial_documents';
    } else if (pathname.includes('owner-details')) {
        id = 'owner_details';
    } else if (pathname.includes('personal-guarantee')) {
        id = 'personal_guarantee';
    } else if (pathname.includes('send-application')) {
        id = 'send_application';
    } else if (pathname.includes('thank-you')) {
        id = 'thank_you';
    } else if (pathname.includes('verification')) {
        id = 'verification';
    } else if (pathname.includes('payment-term')) {
        id = 'payment_term';
    } else if (pathname.includes('billing-information')) {
        id = 'billing_information';
    } else if (pathname.includes('agreement')) {
        id = 'agreement';
    } else {
        id = '';
    }
    return id;
}

function stepInfoReducer(action, state = { id: '', title: '' }) {
    switch (action.type) {
    case 'payment_type':
        return { ...state, title: 'Payment options', id: 'payment_type' };
    case 'personal_details':
        return { ...state, title: 'Authorized Signer Details', id: 'personal_details' };
    case 'business_details':
        return { ...state, title: 'Business Details', id: 'business_details' };
    case 'financial_documents':
        return { ...state, title: 'Financial Statements', id: 'financial_documents' };
    case 'owner_details':
        return { ...state, title: 'Owners\' Details', id: 'owner_details' };
    case 'personal_guarantee':
        return { ...state, title: 'Personal Guaranty', id: 'personal_guarantee' };
    case 'send_application':
        return { ...state, title: 'Send Application', id: 'send_application' };
    case 'verification':
        return { ...state, title: 'Verify Yourself', id: 'verification' };
    case 'payment_term':
        return { ...state, title: 'Choose term', id: 'payment_term' };
    case 'billing_information':
        return { ...state, title: 'Billing Information', id: 'billing_information' };
    case 'agreement':
        return { ...state, title: 'Review & Sign', id: 'agreement' };
    case 'thank_you':
        return { ...state, title: 'Thank you', id: 'thank_you' };
    case 'custom':
        return { ...state, title: action.payload.title, id: state.id };
    default:
        return { id: '', title: '' };
    }
}

function Checkout() {
    const { data: orderDetailsData } = useQuery(GET_ORDER_DETAILS);
    const [workflow, setWorkflow] = useState();
    const [sessionSteps, setSessionSteps] = useState([]);
    const [currentSessionRole, setCurrentSessionRole] = useState('other');

    const flowSettersContext = useContext(FlowSettersContext);
    const flowGettersContext = useContext(FlowGettersContext);

    const [stepInfo, dispatchStepInfo] = useReducer(stepInfoReducer, { id: '', title: '' });

    const { path: currentPath } = useRouteMatch();
    const { pathname } = useLocation();
    const history = useHistory();

    const [getContact, {
        loading: isPersonalDetailsLoading,
        data: personalDetailsData,
    }] = useLazyQuery(GET_CONTACT);

    const [updateContact] = useMutation(UPDATE_CONTACT);
    const [saveSignedAgreement] = useMutation(SAVE_AGREEMENT);
    const [updatePaymentType] = useMutation(UPDATE_PAYMENT_TYPE);
    const [updatePaymentTerm] = useMutation(UPDATE_PAYMENT_TERM);
    const [directUploadFiles] = useDirectUploadFiles();
    const [getBusinessDetails, {
        loading: isBusinessDetailsLoading,
        data: businessDetailsData,
    }] = useLazyQuery(GET_BUSINESS_DETAILS);

    const [getDefaultPaymentMethod, {
        loading: isLoadingDefaultPaymentMethod,
        data: paymentMethodDetailsData,
    }] = useLazyQuery(GET_DEFAULT_PAYMENT_METHOD);

    const [getOrderSignatureURL, {
        loading: isOrderSignatureURLLoading,
        data: orderSignatureURLData,
    }] = useMutation(GET_SIGNATURE_URL);

    const [getOrderMetaData, {
        loading: orderMetaDataLoading,
        data: orderMetaData,
    }] = useLazyQuery(GET_ORDER_META_DATA);

    const [getContactVerificationStatus, {
        loading: contactVerificationStatusLoading,
        data: contactVerificationStatusData,
    }] = useLazyQuery(GET_CONTACT_VERIFICATION_STATUS);

    const [getOrderSummaryData, {
        loading: isOrderSummaryLoading,
        data: orderSummaryData,
    }] = useLazyQuery(GET_ORDER_SUMMARY);

    const isOwnerSession = useCallback(() => (currentSessionRole === 'owner' || currentSessionRole === 'officer' || currentSessionRole === 'owner_officer'), [currentSessionRole]);

    const [updateBusinessDetails] = useMutation(UPDATE_BUSINESS_DETAILS);

    const [updatePersonalGuarantee] = useMutation(UPDATE_PERSONAL_GUARANTEE);

    const [sendApplication] = useMutation(SEND_APPLICATION);

    const [updateOrderApplicationSteps] = useMutation(UPDATE_ORDER_APPLICATION_STEPS);

    const [updateFirstOpenedAt] = useMutation(FIRST_OPENED_AT);

    const [upsertPaymentMethod, { loading: isUpsertingPaymentMethod }] = useMutation(UPSERT_PAYMENT_METHOD);

    const [updateContactVerificationStatus, { loading: updatingContactVerificationStatus }] = useMutation(UPDATE_CONTACT_VERIFICATION_STATUS);

    const { update: updateIntercom } = useIntercom();

    const goToNextStep = useCallback((workflowSteps) => {
        const pageIDsToRouteMappings = {
            business_details: `${currentPath}/business-details`,
            payment_type: `${currentPath}/payment-type`,
            personal_details: `${currentPath}/personal-details`,
            owner_details: `${currentPath}/owner-details`,
            financial_documents: `${currentPath}/financial-documents`,
            personal_guarantee: `${currentPath}/personal-guarantee`,
            send_application: `${currentPath}/send-application`,
            billing_information: `${currentPath}/billing-information`,
            payment_term: `${currentPath}/payment-term`,
            verification: `${currentPath}/verification`,
            agreement: `${currentPath}/agreement`,
            thank_you: `${currentPath}/thank-you`,
        };
        if (workflowSteps) {
            const nextStep = workflowSteps.find((step) => step.status === 'pending');
            if (get(nextStep, 'require_owner', false) && !isOwnerSession()) {
                history.push(pageIDsToRouteMappings.send_application);
            } else {
                const id = get(nextStep, 'name');
                let route = pageIDsToRouteMappings.thank_you;
                if (id) route = pageIDsToRouteMappings[id];
                history.push(route);
            }
        }
    }, [history, currentPath, isOwnerSession]);

    useEffect(() => {
        const fetchOrderSummary = async () => {
            await getOrderSummaryData();
        };
        fetchOrderSummary();
    }, [getOrderSummaryData]);

    useEffect(() => {
        const user = get(orderMetaData, 'session.user', null);
        const orderId = get(orderMetaData, 'session.order.id', null);
        const orderUrlAdmin = !orderId ? '' : `${process.env.REACT_APP_ADMIN_URL}/admin/orders/${orderId}`;
        if (user) {
            FullStory.identify(user.number, {
                company: formatCapital(user.company.name),    
                displayName: formatCapital(user.fullName),
                email: user.email,
            });
            updateIntercom({ 
                name: user.fullName,
                email: user.email,
                created_at: new Date(user.createdAt).valueOf(),
                customAttributes: {
                    application: 'customer-app',
                    environment: process.env.REACT_APP_NODE_ENV,
                    resourceId: user.id,
                    resourceType: 'User',
                    orderUrlAdmin,
                },
            });
        }
    });

    useEffect(() => {
        FullStory.event(`Workflow Step Changed -> ${getPathID(pathname)}`);
    }, [pathname]);

    useEffect(() => {
        setWorkflow(get(orderDetailsData, 'session.order.workflowSteps.steps'));
        flowSettersContext.setVendor(
            {
                type: 'update_vendor',
                payload: get(orderDetailsData, 'session.user.company.seller', {
                    name: '', 
                    logoUrl: '',
                }),
            },
        );
        setCurrentSessionRole(get(orderDetailsData, 'session.user.role', 'other'));
    }, [orderDetailsData, flowSettersContext]);

    useEffect(() => {
        const steps = workflow?.filter((element) => element.status === 'pending').map((element) => element.name);
        setSessionSteps((s) => union(s, steps));
        goToNextStep(workflow);
    }, [workflow, goToNextStep]);

    const onUpdateFirstOpenedAt = useCallback((path) => {
        try {
            updateFirstOpenedAt({
                variables: {
                    currentStep: path,
                },
            });
        } catch (e) {
            console.log(e);
        }
    }, [updateFirstOpenedAt]);


    useEffect(() => {
        let path = '';
        dispatchStepInfo({ type: getPathID(pathname) });
        getOrderMetaData();
        switch (true) {
        case pathname.includes('payment-type'): path = 'payment_type'; break;
        case pathname.includes('personal-details'): path = 'personal_details'; getContact(); break;
        case pathname.includes('business-details'): path = 'business_details'; getBusinessDetails(); break;
        case pathname.includes('billing-information'): path = 'billing_information'; getDefaultPaymentMethod(); break;
        case pathname.includes('payment-term'): path = 'payment_term'; break;
        case pathname.includes('agreement'): path = 'agreement'; getOrderSignatureURL(); break;
        case pathname.includes('verification'): path = 'verification'; getContactVerificationStatus(); break;
        default: break;
        }
        if (path !== '') {
            onUpdateFirstOpenedAt(path);
        }
    }, [
        pathname,
        getOrderSignatureURL,
        getContact,
        getBusinessDetails,
        getOrderMetaData,
        getDefaultPaymentMethod,
        getContactVerificationStatus,
        onUpdateFirstOpenedAt,
    ]);

    useEffect(() => {
        let className = '';
        if (flowGettersContext.showOrderSummary || flowGettersContext.showNetTermsSummary) {
            className = 'lg:tw-min-w-50vw lg:tw-max-w-55vw sm:tw-min-w-80vw sm:tw-max-w-80vw tw-w-full';
        } else if (stepInfo.id === 'agreement') {
            className = 'sm:tw-w-60vw sm:tw-h-90vh tw-w-full';
        } else {
            className = 'sm:tw-min-w-60vw sm:tw-max-w-60vw lg:tw-min-w-35vw lg:tw-max-w-35vw tw-w-full';
        }
        flowSettersContext.setDimensionClass(className);
    }, [flowGettersContext, flowSettersContext, stepInfo.id]);

    useEffect(() => {
        if (stepInfo.id !== 'thank_you') {
            flowSettersContext.setStepTitle(stepInfo.title);
            flowSettersContext.setStepBodyClass('tw-mt-4');
        } else {
            flowSettersContext.setStepTitle('');
            flowSettersContext.setStepBodyClass('');
        }
    }, [flowSettersContext, stepInfo.title, stepInfo.id]);

    useEffect(() => {
        const isLocBasedCheckout = workflow?.find((elem) => elem.name === 'payment_type');

        const showNetTermsAmount = isLocBasedCheckout && (!pathname.includes('payment-type') && !pathname.includes('payment-term') && !pathname.includes('agreement')
            && !pathname.includes('thank-you'));

        const showOrderSummary = get(orderMetaData, 'session.order.status') === 'checkout'
            && !pathname.includes('agreement')
            && !pathname.includes('thank-you')
            && !isLocBasedCheckout;

        flowSettersContext.setShowOrderSummary(showOrderSummary);
        flowSettersContext.setShowNetTermsSummary(showNetTermsAmount);
    }, [flowSettersContext, orderMetaData, pathname, workflow]);

    useEffect(() => {
        let pills;

        if (stepInfo.id === 'thank_you' || stepInfo.id === 'send_application') {
            pills = [];
        } else {
            pills = sessionSteps.map((sstep) => {
                const step = workflow && workflow.filter((elem) => elem.name === sstep)[0];
                if (!step) return null;

                let icon = step.name === stepInfo.id ? bluePoint : greyPoint;
                if (step.status === 'complete') {
                    icon = blueTick;
                }
                return step.status === 'locked' || (!isOwnerSession() && step.require_owner) ? null : <img className="tw-w-5 tw-pl-2" src={icon} alt="" key={step.name} />;
            });
        }

        flowSettersContext.setStepPills(pills);
    }, [sessionSteps, workflow, isOwnerSession, stepInfo, flowSettersContext]);

    const paymentInterval = useMemo(() => {
        const terms = get(orderMetaData, 'session.order.company.creditAppraisal.formattedAllowedPaymentTermsWithLocRates');
        return terms ? getPaymentInterval(terms) : '';
    }, [orderMetaData]);

    const onPersonalDetailsFormSubmit = async (values, { setErrors }) => {
        try {
            const responseData = await updateContact({
                variables: {
                    ...values,
                },
            });
            setErrors(get(responseData, 'errors', {}));
            setWorkflow(get(responseData, 'data.updatePersonalDetails.order.workflowSteps.steps'));
            setCurrentSessionRole(get(responseData, 'data.updatePersonalDetails.user.role'));
        } catch (e) {
            console.log(e);
        }
    };

    const onBusinessDetailsFormSubmit = async (values, { setErrors }) => {
        try {
            const responseData = await updateBusinessDetails({
                variables: {
                    ...values,
                },
            });
            setErrors(get(responseData, 'errors', {}));
            setWorkflow(get(responseData, 'data.updateBusinessDetails.order.workflowSteps.steps'));
        } catch (e) {
            console.log(e);
        }
    };

    const onFinancialDocumentsSubmit = async (values) => {
        try {
            await directUploadFiles([...values.financials], {
                documentType: 'financial',
                orderId: get(orderMetaData, 'session.order.id', null),
                companyId: get(orderMetaData, 'session.user.company.id', null),
            });

            const responseData = await updateOrderApplicationSteps();
            setWorkflow(get(responseData, 'data.updateOrderApplicationSteps.order.workflowSteps.steps'));
        } catch (e) {
            console.log(e);
        }
    };

    const onPersonalGuaranteeSubmit = async (values, { setErrors }) => {
        try {
            const responseData = await updatePersonalGuarantee({
                variables: {
                    ...values,
                },
            });
            setErrors(get(responseData, 'errors', {}));
            setWorkflow(get(responseData, 'data.updatePersonalGuarantee.order.workflowSteps.steps'));
        } catch (e) {
            console.log(e);
        }
    };

    const onSendApplication = async (values, { setErrors }) => {
        try {
            const responseData = await sendApplication({
                variables: {
                    ...values,
                },
            });
            setErrors(get(responseData, 'errors', {}));
            setWorkflow(get(responseData, 'data.sendApplication.order.workflowSteps.steps'));
        } catch (e) {
            console.log(e);
        }
    };

    const onPaymentMethodSubmit = async (values) => {
        try {
            const responseData = await upsertPaymentMethod({
                variables: { ...values },
            });
            setWorkflow(get(responseData, 'data.upsertPaymentMethod.order.workflowSteps.steps'));
        } catch (e) {
            console.log(e);
        }
    };

    const onContactVerify = async () => {
        try {
            const responseData = await updateContactVerificationStatus();
            setWorkflow(get(responseData, 'data.updateContactVerificationStatus.order.workflowSteps.steps'));
        } catch (e) {
            console.log(e);
        }
    };

    const onSignature = async () => {
        try {
            const responseData = await saveSignedAgreement();
            setWorkflow(get(responseData, 'data.saveSignedAgreement.order.workflowSteps.steps'));
        } catch (e) {
            console.log(e);
        }
    };

    const onPaymentTypeSelection = async (values) => {
        try {
            const responseData = await updatePaymentType({
                variables: {
                    ...values,
                },
            });
            setWorkflow(get(responseData, 'data.updatePaymentType.order.workflowSteps.steps'));
        } catch (e) {
            console.log(e);
        }
    };

    const handlePaymentTerm = async (value, isSubmitAction = false) => {
        try {
            const responseData = await updatePaymentTerm({
                variables: {
                    ...value,
                    proceedNext: !!isSubmitAction,
                },
            });
            await getOrderSummaryData();
            setWorkflow(get(responseData, 'data.updatePaymentTerm.order.workflowSteps.steps'));
        } catch (e) {
            console.log(e);
        }
    };

    const rightSection = () => {
        switch (true) {
        case flowGettersContext.showOrderSummary:
            return (
                <OrderDetails
                    isLoading={isOrderSummaryLoading} 
                    orderSummaryData={get(orderSummaryData, 'session.order')}
                />
            );
        default:
            return null;
        }
    };
    return (
        <>
            <div className={`${(flowGettersContext.showOrderSummary || flowGettersContext.showNetTermsSummary) ? 'sm:tw-flex sm:tw-divide-x sm:tw-divide-grey-3 sm:tw-mt-3' : 'tw-mt-3'}`}>
                <div className={`${(flowGettersContext.showOrderSummary || flowGettersContext.showNetTermsSummary) ? 'tw-pr-0 sm:tw-pr-6 sm:tw-flex-3' : ''}`}>
                    <Switch>
                        <Route
                            path={`${currentPath}/payment-type`}
                            exact
                            render={() => (
                                <PaymentType onSelect={() => onPaymentTypeSelection} />
                            )}
                        />
                        <Route
                            path={`${currentPath}/personal-details`}
                            exact
                            render={() => (
                                <PersonalDetails
                                    isFormLoading={isPersonalDetailsLoading}
                                    personalDetailsData={get(personalDetailsData, 'session.user')}
                                    onFormSubmit={onPersonalDetailsFormSubmit}
                                    button={({ isFormLoading, isSubmitting }) => (
                                        <div className="tw-flex tw-flex-col sm:tw-flex-row-reverse tw-justify-center sm:tw-justify-start tw-mt-4">
                                            <Button
                                                className="tw-bg-blue"
                                                label="Next"
                                                type="submit"
                                                disabled={isFormLoading || isSubmitting}
                                            />
                                        </div>
                                    )}
                                />
                            )}
                        />
                        <Route
                            path={`${currentPath}/business-details`}
                            exact
                            render={() => (
                                <BusinessDetails
                                    isFormLoading={isBusinessDetailsLoading}
                                    businessDetailsData={{
                                        ...get(businessDetailsData, 'session.user.company'),
                                        employeeCount: null,
                                        annualRevenue: null,
                                    }}
                                    onFormSubmit={onBusinessDetailsFormSubmit}
                                    button={({ isFormLoading, isSubmitting }) => (
                                        <div className="tw-flex tw-flex-col sm:tw-flex-row-reverse tw-justify-center sm:tw-justify-start tw-mt-4">
                                            <Button
                                                className="tw-bg-blue"
                                                label="Next"
                                                type="submit"
                                                disabled={isFormLoading || isSubmitting}
                                            />
                                        </div>
                                    )}
                                />
                            )}
                        />
                        <Route
                            path={`${currentPath}/financial-documents`}
                            exact
                            render={() => (
                                <FinancialDocuments
                                    onFormSubmit={onFinancialDocumentsSubmit}
                                    button={({ isFormLoading, isSubmitting, values }) => (
                                        <div className="tw-flex tw-flex-col sm:tw-flex-row-reverse tw-justify-center sm:tw-justify-start tw-mt-4">
                                            <Button
                                                className="tw-bg-blue"
                                                label="Next"
                                                type="submit"
                                                disabled={isFormLoading || isSubmitting || !values.financials.length}
                                            />
                                        </div>
                                    )}
                                />
                            )}
                        />
                        <Route
                            path={`${currentPath}/personal-guarantee`}
                            exact
                            render={() => (
                                <PersonalGuarantee
                                    onFormSubmit={onPersonalGuaranteeSubmit}
                                    button={(agreed, isSubmitting) => (
                                        <div className="tw-flex tw-flex-col sm:tw-flex-row-reverse tw-justify-center sm:tw-justify-start tw-mt-4 sm:tw-mt-0">
                                            <Button
                                                className="tw-bg-blue"
                                                label="Next"
                                                type="submit"
                                                disabled={!agreed || isSubmitting}
                                            />
                                        </div>
                                    )}
                                />
                            )}
                        />
                        <Route
                            path={`${currentPath}/send-application`}
                            exact
                            render={() => (
                                <SendApplication
                                    orderMetaData={orderMetaData}
                                    orderMetaDataLoading={orderMetaDataLoading}
                                    onFormSubmit={onSendApplication}
                                    button={({ isSubmitting, isValid }) => (
                                        <div className="tw-flex tw-flex-col sm:tw-flex-row-reverse tw-justify-center sm:tw-justify-start tw-mt-4">
                                            <Button
                                                className="tw-bg-blue"
                                                label="Send"
                                                type="submit"
                                                disabled={isSubmitting || !isValid || orderMetaDataLoading}
                                            />
                                        </div>
                                    )}
                                />
                            )}
                        />
                        <Route
                            path={`${currentPath}/billing-information`}
                            exact
                            render={() => (
                                <PaymentMethod
                                    paymentMethodDetails={get(paymentMethodDetailsData, 'session.user.company.defaultPaymentMethod', null)}
                                    invoiceEnabled={get(paymentMethodDetailsData, 'session.order.invoiceEnabled', false)}
                                    isFormLoading={isLoadingDefaultPaymentMethod}
                                    isFormSubmitting={isUpsertingPaymentMethod}
                                    button={({ btnDisabled, isSubmitting }, values) => {
                                        return (
                                            <div className="tw-flex tw-flex-col sm:tw-flex-row-reverse tw-justify-center sm:tw-justify-start tw-mt-24">
                                                <Button
                                                    className="tw-bg-blue"
                                                    label="Next"
                                                    disabled={btnDisabled || isSubmitting}
                                                    onClick={() => onPaymentMethodSubmit(values)}
                                                />
                                            </div>
                                        );
                                    }}
                                />
                            )}
                        />
                        <Route
                            path={`${currentPath}/verification`}
                            exact
                            render={() => (
                                <Verification 
                                    user={get(contactVerificationStatusData, 'session.user')}
                                    kycPreferences={get(orderDetailsData, 'session.user.company.kycPreferences', [])}
                                    isLoading={contactVerificationStatusLoading || updatingContactVerificationStatus}
                                    button={(internalComponentLoading) => (
                                        <div className="tw-flex tw-flex-col sm:tw-flex-row-reverse tw-justify-center sm:tw-justify-start tw-mt-8">
                                            <Button
                                                className="tw-bg-blue"
                                                label="Next"
                                                disabled={contactVerificationStatusLoading || updatingContactVerificationStatus || internalComponentLoading}
                                                onClick={() => onContactVerify()}
                                            />
                                        </div>
                                    )}
                                />
                            )}
                        />
                        <Route
                            path={`${currentPath}/payment-term`}
                            exact
                            render={() => (
                                <PaymentTerms
                                    isLoading={orderMetaDataLoading}
                                    formattedAllowedPaymentTerms={get(orderMetaData, 'session.order.company.creditAppraisal.formattedAllowedPaymentTermsWithLocRates')}
                                    onSubmit={async (value) => {
                                        await handlePaymentTerm(value, true);
                                    }}
                                    onSelect={async (value) => {
                                        await handlePaymentTerm({ paymentTerm: value });
                                    }}
                                />
                            )}
                        />
                        <Route
                            path={`${currentPath}/agreement`}
                            exact
                            render={() => (
                                isOrderSignatureURLLoading
                                    ? null : (
                                        <Agreement
                                            orderSignatureData={get(orderSignatureURLData, 'generateAgreementUrl.order')}
                                            onSignature={onSignature}
                                        />
                                    )
                            )}
                        />
                        <Route
                            path={`${currentPath}/thank-you`}
                            exact
                            render={() => (
                                <ThankYou
                                    orderStatus={get(orderMetaData, 'session.order.status')}
                                />
                            )}
                        />
                        <Redirect to={`${currentPath}`} />
                    </Switch>
                </div>
                <div className="tw-hidden sm:tw-pl-6 sm:tw-flex-2 sm:tw-block">
                    {rightSection()}
                </div>
            </div>


            <div className={`${(flowGettersContext.showOrderSummary || flowGettersContext.showNetTermsSummary) ? 'tw-hidden sm:tw-block' : 'tw-block'}`}>
                <div className="tw-mt-3 tw-mb-3">
                    <Divider />
                </div>
                <div className="tw-flex tw-flex-row-reverse tw-justify-start tw-mt-6">
                    <div className="tw-w-44">
                        <img className="tw-w-full tw-h-full" src={poweredBy} alt="Powered by Vartana" />
                    </div>
                </div>
            </div>

            {(flowGettersContext.showOrderSummary) ? (
                <div className="sm:tw-hidden tw-absolute tw-inset-x-0 tw-bottom-0">
                    <MobileOrderDetails
                        isDataReady={isOrderSummaryLoading}
                        orderSummaryData={get(orderSummaryData, 'session.order')}
                        paymentInterval={paymentInterval}
                        orderMetaData={orderMetaData}
                        viewMode="BASE_ORDER_SUMMARY"
                    />
                </div>
            ) : null}
            {(flowGettersContext.showNetTermsSummary) ? (
                null
            ) : null}
        </>
    );
}

export default Checkout;
