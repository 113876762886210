import React from 'react';
import PropTypes from 'prop-types';

function Loader({ 
    className,
    containerClassName,
}) {
    return (
        <div className={containerClassName}>
            <div className={`tw-w-12 tw-h-12 tw-border-2 tw-block tw-lds-dual-ring tw-m-auto ${className}`} />
        </div>
    );
}


Loader.propTypes = {
    className: PropTypes.string,
    containerClassName: PropTypes.string,
};

Loader.defaultProps = {
    className: '',
    containerClassName: '',
};

export default Loader; 
