/* eslint-disable import/prefer-default-export */

import { useLocation } from 'react-router-dom';
import { useLayoutEffect, useState } from 'react';
import { get } from 'lodash';
import { 
    useMutation,
} from '@apollo/client';

import { 
    CREATE_DIRECT_UPLOADS, 
    ATTACH_BLOBS,
    ATTACH_BLOBS_CREDIT_APPRAISAL,
} from '../../graphql/queries/directUpload';
import { calculateFilesMetadata } from '../helpers';
import { performUpload } from '../../api/directUpload';

export const useQueryParams = () => new URLSearchParams(useLocation().search);

export const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
};

export const useDirectUploadFiles = () => {
    const [createDirectUploads] = useMutation(CREATE_DIRECT_UPLOADS);
    const [attachBlobs] = useMutation(ATTACH_BLOBS);

    return [function directUploadFiles(files, attachBlobData) {
        return new Promise((resolve, reject) => {
            calculateFilesMetadata(files).then(((filesMetadata) => {
                createDirectUploads({ 
                    variables: {
                        directUploads: filesMetadata,
                    },
                }).then((responseData) => {
                    const directUploads = get(responseData, 'data.createDirectUploads.directUploads');
                    const pendingUploads = directUploads.map((directUpload, index) => {
                        return performUpload(files[index], JSON.parse(directUpload.headers), directUpload.url);
                    });
                    Promise.all(pendingUploads).then(() => {
                        const merged = files.map((file, index) => ({
                            file,
                            metadata: { ...directUploads[index] },
                        }));
                        const attachBlobsInput = merged.map((file) => ({
                            ...attachBlobData,
                            blobId: file.metadata.blobId,
                        }));
                        attachBlobs({ 
                            variables: {
                                attachBlobs: attachBlobsInput,
                            },
                        }).then(() => {
                            resolve(merged);
                        }).catch((error) => reject(error));
                    }).catch((error) => reject(error));
                }).catch((error) => reject(error));
            })).catch((error) => reject(error));
        });
    }];
};

export const useDirectUploadFilesAppraisal = () => {
    const [createDirectUploads] = useMutation(CREATE_DIRECT_UPLOADS);
    const [attachBlobs] = useMutation(ATTACH_BLOBS_CREDIT_APPRAISAL);

    return [function directUploadFiles(files, attachBlobData) {
        return new Promise((resolve, reject) => {
            calculateFilesMetadata(files).then(((filesMetadata) => {
                createDirectUploads({ 
                    variables: {
                        directUploads: filesMetadata,
                    },
                }).then((responseData) => {
                    const directUploads = get(responseData, 'data.createDirectUploads.directUploads');
                    const pendingUploads = directUploads.map((directUpload, index) => {
                        return performUpload(files[index], JSON.parse(directUpload.headers), directUpload.url);
                    });
                    Promise.all(pendingUploads).then(() => {
                        const merged = files.map((file, index) => ({
                            file,
                            metadata: { ...directUploads[index] },
                        }));
                        const attachBlobsInput = merged.map((file) => ({
                            ...attachBlobData,
                            blobId: file.metadata.blobId,
                        }));
                        attachBlobs({ 
                            variables: {
                                attachBlobs: attachBlobsInput,
                            },
                        }).then(() => {
                            resolve(merged);
                        }).catch((error) => reject(error));
                    }).catch((error) => reject(error));
                }).catch((error) => reject(error));
            })).catch((error) => reject(error));
        });
    }];
};
