import React from 'react';
import PropTypes from 'prop-types';

import Loader from '../Loader';

function AutoLoad({ 
    children,
    loading,
    className,
    containerClassName,
}) {
    return (
        loading ? (
            <Loader 
                isLoading 
                className={`tw-w-10 tw-h-10 tw-mt-6 ${className}`} 
                containerClassName={containerClassName}
            />
        ) : (children)
    );
}


AutoLoad.propTypes = {
    loading: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    className: PropTypes.string,
    containerClassName: PropTypes.string,
};

AutoLoad.defaultProps = {
    className: '',
    containerClassName: '',
};

export default AutoLoad; 
