import { gql } from '@apollo/client';

export const GET_BUSINESS_DETAILS = gql`
    query getBusinessDetails {
        session {
            id
            user {
                company {
                    businessName
                    ein
                    einUnmasked
                    street
                    zip
                    city
                    state
                    country
                    dunsNumber
                    dateStarted
                    entityType
                    suite
                    employeeCount
                    annualRevenue
                }
            }
        }
    }
`;

export const UPDATE_BUSINESS_DETAILS = gql`
    mutation(
        $businessName: String!, 
        $ein: String!, 
        $street: String!, 
        $zip: String!, 
        $city: String!, 
        $state: String!, 
        $dunsNumber: String!,
        $dateStarted: ISO8601Date!,
        $entityType: String!,
        $suite: String!
        $employeeCount: Int,
        $annualRevenue: Float,
    ) {
        updateBusinessDetails(
            businessName: $businessName
            ein: $ein
            street: $street
            city: $city
            state: $state
            zip: $zip
            dunsNumber: $dunsNumber
            dateStarted: $dateStarted
            entityType: $entityType
            suite: $suite
            employeeCount: $employeeCount
            annualRevenue: $annualRevenue
        ) {
            id
            user {
                id
            }
            order {
                id
            }
        }
    }
`;

export const UPDATE_BUSINESS_DETAILS_CREDIT_APPRAISAL = gql`
    mutation(
        $businessName: String!, 
        $ein: String!, 
        $street: String!, 
        $zip: String!, 
        $city: String!, 
        $state: String!, 
        $dunsNumber: String!,
        $dateStarted: ISO8601Date!,
        $entityType: String!,
        $suite: String!
        $employeeCount: Int,
        $annualRevenue: Float,
    ) {
        updateBusinessDetailsCreditAppraisal(
            businessName: $businessName
            ein: $ein
            street: $street
            city: $city
            state: $state
            zip: $zip
            dunsNumber: $dunsNumber
            dateStarted: $dateStarted
            entityType: $entityType
            suite: $suite
            employeeCount: $employeeCount
            annualRevenue: $annualRevenue
        ) {
            id
            user {
                id
            }
            creditAppraisal {
                id
                number
                applicationSteps
            }
        }
    }
`;
