import React, { useEffect } from 'react';

import { 
    Route, 
    Switch, 
    Redirect, 
    useLocation,
} from 'react-router-dom';

import { IntercomProvider } from 'react-use-intercom';
import * as FullStory from '@fullstory/browser';


import ErrorBoundary from './components/ErrorBoundary';

import Flow from './pages/Flow';
import Error from './pages/Error';

FullStory.init({ 
    orgId: '129FQB',
    devMode: process.env.REACT_APP_NODE_ENV === 'staging' || process.env.REACT_APP_NODE_ENV === 'preview' || process.env.REACT_APP_NODE_ENV === 'development',
});

function RootComponent() {
    useEffect(() => {
        FullStory.getCurrentSessionURL();
    });

    const location = useLocation();

    return (
        <IntercomProvider 
            appId={process.env.REACT_APP_INTERCOM_ID}
            autoBoot
        >
            <ErrorBoundary>
                <div className="tw-font-sans tw-min-h-screen tw-bg-white sm:tw-bg-main-bg">
                    <Switch>
                        {/* temporary redirection */}
                        <Route
                            path="/checkout"
                            exact
                            render={() => <Redirect to={{ pathname: '/order/checkout', search: location.search }} />}
                        />
                        <Route
                            path="/appraisal"
                            exact
                            render={() => <Redirect to={{ pathname: '/customer/appraisal', search: location.search }} />}
                        />
                        <Route 
                            path={[
                                '/order',
                                '/customer',
                            ]}
                            component={Flow} 
                        />
                        <Route 
                            render={() => <Error errorTitle="404" errorMessage="Page not found" />}
                        />
                    </Switch>
                </div>
            </ErrorBoundary>
        </IntercomProvider>
    );
}

export default RootComponent;
