import { gql } from '@apollo/client';

export const GET_CREDIT_APPRAISAL_APPLICATION_STEPS = gql`
    query {
        session {
            creditAppraisal {
                id
                number
                applicationSteps
            }
            user {
                company {
                    id
                    seller {
                        name
                        logoUrl
                        faviconUrl
                    }
                }
                role
            }
        }
    }
`;

export const GET_CREDIT_APPRAISAL = gql`
    query {
        session {
            creditAppraisal {
                id
                number
                applicationSteps
            }
            user {
                id
                number
                fullName
                email
                createdAt
                company {
                    id
                    businessName
                    seller {
                        name
                        logoUrl
                        faviconUrl
                    }
                }
                role
            }
        }
    }
`;

// export const GET_ORDER_SUMMARY = gql`
//     query {
//         session {
//             id
//             order {
//                 id
//                 status
//                 workflowSteps
//                 startDate
//                 endDate
//                 term
//                 formattedTerm
//                 formattedAmount
//                 formattedPayment
//                 formattedFirstPayment
//                 formattedCustomerApplicationFee
//                 formattedTaxes
//                 formattedShippingCharges
//                 formattedSummaryTotal
//                 customerApplicationFee
//                 taxes
//                 shippingCharges
//                 orderItems {
//                     id
//                     name
//                     description
//                     quantity
//                     formattedAmount
//                     formattedUnitPrice
//                 }
//             }
//         }
//     }
// `;



// export const UNDERWRITE_OWNER = gql`
//     mutation (
//         $applicationSent: Boolean!
//     ) {
//         underwriteOwner (
//             applicationSent: $applicationSent
//         ) {
//             order {
//                 id
//                 status
//                 workflowSteps
//             }
//         }
//     }
// `;

export const UPDATE_CREDIT_APPRAISAL_APPLICATION_STEPS = gql`
    mutation {
        updateCreditAppraisalApplicationSteps {
            creditAppraisal {
                id
                number
                applicationSteps
            }
        }
    }
`;

export const FIRST_OPENED_AT = gql`
    mutation($currentStep: String!) {
        updateApplicationFirstOpenedAt(currentStep: $currentStep) {
            creditAppraisal {
                id
                number
                applicationSteps
            }
        }
    }
`;
