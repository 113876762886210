import { gql } from '@apollo/client';

export const GET_ORDER_DETAILS = gql`
    query {
        session {
            id
            order {
                id
                status
            }
            user {
                company {
                    kycPreferences
                    seller {
                        id
                        name
                        logoUrl
                        faviconUrl
                    }
                }
                role
            }
        }
    }
`;

export const PREVIEW_AGREEMENT = gql`
    mutation($orderId: ID!) {
        previewAgreement(
        orderId: $orderId
        ) {
            id
            number
            encodedAgreement
        }
    }
`;

export const GET_ORDER_SUMMARY = gql`
    query {
        session {
            id
            order {
                id
                status
                startDate
                endDate
                term
                numPmts
                billingFrequency
                factor
                formattedFactor
                factorRate
                formattedFactorRate
                formattedTerm
                formattedAmount
                formattedPayment
                formattedFirstPayment
                formattedCustomerApplicationFee
                formattedTaxes
                formattedShippingCharges
                formattedSummaryTotal
                customerApplicationFee
                upfrontSoftcosts
                softCosts
                formattedSoftCosts
                taxes
                shippingCharges
                paymentType
                paymentTerm
                formattedPaymentTerm
                orderItems {
                    id
                    name
                    description
                    quantity
                    formattedAmount
                    formattedUnitPrice
                }
            }
        }
    }
`;

export const ORDER_SUMMARY_VIEWED = gql`
    mutation {
        markOrderViewed {
            id
            order {
                id
                status
            }
        }
    }
`;

export const GET_SIGNATURE_URL = gql`
    mutation {
        generateAgreementUrl {
            id
            order {
                id
                status
                signatureUrl
            }
        }
    }
`;

export const GET_ORDER_META_DATA = gql`
    query {
        session {
            id
            user {
                id
                number
                fullName
                email
                isOwner
                createdAt
                company {
                    id
                    name
                    primaryUser {
                        fullName
                    }
                }
            }
            order {
                id
                status
                applicationSent
                loanDecision
                company {
                    id
                    name
                    primaryUser {
                        fullName
                    }
                }
                
            }
        }
    }
`;

export const SAVE_AGREEMENT = gql`
    mutation {
        saveSignedAgreement {
            id
            order {
                id
                status
            }
        }
    }
`;

export const FIRST_OPENED_AT = gql`
  mutation($currentStep: String!) {
    updateFirstOpenedAt(currentStep: $currentStep) {
      order {
        id
        status
      }
    }
  }
`;

export const UNDERWRITE_OWNER = gql`
    mutation (
        $applicationSent: Boolean!
    ) {
        underwriteOwner (
            applicationSent: $applicationSent
        ) {
            order {
                id
                status
            }
        }
    }
`;

export const UPDATE_ORDER_APPLICATION_STEPS = gql`
    mutation {
        updateOrderApplicationSteps {
            order {
                id
                status
            }
        }
    }
`;

export const UPDATE_PAYMENT_TYPE = gql`
    mutation(
        $paymentType: String!,
    ) {
        updatePaymentType (
            paymentType: $paymentType
        ) {
            order {
                id
                paymentType
            }
        }
    }
`;

export const UPDATE_PAYMENT_TERM = gql`
    mutation (
        $paymentTerm: Int!,
        $proceedNext: Boolean!
    ) {
        updatePaymentTerm (
            paymentTerm: $paymentTerm,
            proceedNext: $proceedNext
        ) {
            order {
                id
                paymentTerm
                formattedFactor
            }
        }
    }
`;
