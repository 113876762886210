import moment from 'moment';
import { mapKeys, camelCase, capitalize } from 'lodash';
import SparkMD5 from 'spark-md5';
import { FileChecksum } from '@rails/activestorage/src/file_checksum';

export const initializeNullValuesToEmptyStrings = (obj) => JSON.parse(JSON.stringify(obj, (k, v) => (v === null ? '' : v)));
export const parseDateStringToDateFormat = (obj) => (obj ? moment(obj).format('MM/DD/YYYY') : obj);

export const mapGraphqlErrorsToObject = (errors = []) => {
    const mapped = {};
    if (errors) {
        Object.entries(errors).forEach(([key, val]) => {
            mapped[camelCase(key)] = val.join(',');
        });
    }
    return mapped;
};

export const cleanPhoneNumber = (phoneNumber = '') => {
    const cleaned = phoneNumber
        .replace(/\s+/g, '') // remove spaces
        .replace(/[\])}[{(]/g, '') // remove braces ()
        .replace(/-/g, ''); // remove hyphens
    return cleaned;
};

export const getMonthsDifference = (year1, year2) => {
    return moment(year1).diff(moment(year2), 'months', true);
};

export const mapKeysToCamelCase = (obj) => mapKeys(obj, (v, k) => camelCase(k));

export const maskString = (str) => `x${str.substr(str.length - 4)}`;

export const calculateChecksum = (file) => {
    return new Promise((resolve, reject) => {
        FileChecksum.create(file, (error, checksum) => {
            if (error) reject(error);
            else resolve(checksum);
        });
    });
};

export const calculateFilesMetadata = (files) => {
    return new Promise((resolve, reject) => {
        const pendingJobs = files.map((file) => calculateChecksum(file));
        Promise.all(pendingJobs).then((checksums) => {
            return files.map((file, index) => ({
                filename: file.name,
                byteSize: file.size,
                checksum: checksums[index],
                contentType: file.type,
            }));
        }).then((filesMetadata) => {
            resolve(filesMetadata);
        }).catch((error) => reject(error));
    });
};

export const calculateMD5 = (file) => {
    return new Promise((resolve, reject) => {
        const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
        const chunkSize = 2097152; // read chunks of 2MB
        const chunks = Math.ceil(file.size / chunkSize);
        let currentChunk = 0;
        const sparkBuffer = new SparkMD5.ArrayBuffer();
        const fileReader = new FileReader();

        const loadNext = () => {
            const start = currentChunk * chunkSize;
            let end = start + chunkSize;
            if (end > file.size) end = file.size;
            fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
        };

        fileReader.onload = (e) => {
            sparkBuffer.append(e.target.result); 
            currentChunk += 1;
            if (currentChunk < chunks) {
                loadNext();
            } else {
                const md5 = sparkBuffer.end();
                resolve(md5);
            }
        };
        
        fileReader.onerror = (e) => {
            reject(e);
        };
    
        loadNext();
    });
};

export const getAPIURL = () => {
    const location = window.location.hostname;
    return location.includes('com') ? process.env.REACT_APP_API_NEW_URL : process.env.REACT_APP_API_URL;
};

export function formatCapital(title) {
    return capitalize(title);
}

export const getPaymentInterval = (terms) => {
    switch (true) {
    case terms.length === 0:
        return '';
    case terms.length === 1:
        return `${terms[0].value} days`;
    default:
        return `${terms[0].value}-${terms[terms.length - 1].value} days`;
    }
};

export const getFormattedPaymentTermPrice = (price) => {
    return price === '0' ? 0 : parseFloat((Number(price) * 100).toFixed(2));
};
