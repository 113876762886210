/* eslint-disable no-unused-vars */
/* eslint-disable default-param-last */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unstable-nested-components */
import React, { 
    useEffect,
    useState,
    useCallback,
    useReducer,
    useContext,
} from 'react';

import { 
    Route, 
    Switch,
    Redirect,
    useRouteMatch,
    useLocation,
    useHistory,
} from 'react-router-dom';

import * as FullStory from '@fullstory/browser';
import { useIntercom } from 'react-use-intercom';

import { 
    useLazyQuery,
    useMutation,
} from '@apollo/client';

import loadable from '@loadable/component';

import { get, union } from 'lodash';

import Button from '../../components/Button';
import Divider from '../../components/Divider';

import { 
    GET_CONTACT,
    UPDATE_CONTACT_CREDIT_APPRAISAL,
} from '../../graphql/queries/contact';

import {
    GET_BUSINESS_DETAILS,
    UPDATE_BUSINESS_DETAILS_CREDIT_APPRAISAL,
} from '../../graphql/queries/business';

import { 
    GET_CREDIT_APPRAISAL,
    UPDATE_CREDIT_APPRAISAL_APPLICATION_STEPS,
    FIRST_OPENED_AT,
} from '../../graphql/queries/customer';

import { FlowSettersContext, FlowGettersContext } from '../../utils/contexts';

import { useDirectUploadFilesAppraisal } from '../../utils/hooks';
import { formatCapital } from '../../utils/helpers';

import greyPoint from '../../assets/gray-point.svg';
import bluePoint from '../../assets/blue-point.svg';
import blueTick from '../../assets/blue-tick.svg';
import poweredBy from '../../assets/powered-by.png';

import Loader from '../../components/Loader';

const PersonalDetails = loadable(() => import('../common/PersonalDetails'), {
    fallback: <Loader isLoading className="tw-w-20 tw-h-20 tw-mt-6" />,
});
const BusinessDetails = loadable(() => import('../common/BusinessDetails'), {
    fallback: <Loader isLoading className="tw-w-20 tw-h-20 tw-mt-6" />,
});
const FinancialDocuments = loadable(() => import('../common/FinancialDocuments'), {
    fallback: <Loader isLoading className="tw-w-20 tw-h-20 tw-mt-6" />,
});
const ThankYou = loadable(() => import('./ThankYou'), {
    fallback: <Loader isLoading className="tw-w-20 tw-h-20 tw-mt-6" />,
});

function getPathID(pathname = '') {
    let id = '';
    if (pathname.includes('personal-details')) {
        id = 'personal_details';
    } else if (pathname.includes('business-details')) {
        id = 'business_details';
    } else if (pathname.includes('financial-documents')) {
        id = 'financial_documents';
    } else if (pathname.includes('thank-you')) {
        id = 'thank_you';
    } else {
        id = '';
    }
    return id;
}  

function stepInfoReducer(state = { id: '', title: '' }, action) {
    switch (action.type) {
    case 'personal_details':
        return { ...state, title: 'Authorized Signer Details', id: 'personal_details' };
    case 'business_details':
        return { ...state, title: 'Business Details', id: 'business_details' };
    case 'financial_documents':
        return { ...state, title: 'Financial Statements', id: 'financial_documents' };
    case 'thank_you':
        return { ...state, title: 'Thank you', id: 'thank_you' };
    case 'custom':
        return { ...state, title: action.payload.title, id: state.id };
    default:
        return { id: '', title: '' };
    }
}

function Appraisal() {
    const [workflow, setWorkflow] = useState();
    const [sessionSteps, setSessionSteps] = useState([]);
    const [currentSessionRole, setCurrentSessionRole] = useState('other');

    const [updateFirstOpenedAt] = useMutation(FIRST_OPENED_AT);

    const flowSettersContext = useContext(FlowSettersContext);
    const flowGettersContext = useContext(FlowGettersContext);

    const [stepInfo, dispatchStepInfo] = useReducer(stepInfoReducer, { id: '', title: '' });

    const { path: currentPath } = useRouteMatch();
    const { pathname } = useLocation();
    const history = useHistory();
    
    const [getContact, { 
        loading: isPersonalDetailsLoading, 
        data: personalDetail,
    }] = useLazyQuery(GET_CONTACT);
    
    const [updateContact] = useMutation(UPDATE_CONTACT_CREDIT_APPRAISAL);
    const [updateCreditAppraisalApplicationSteps] = useMutation(UPDATE_CREDIT_APPRAISAL_APPLICATION_STEPS);

    const [directUploadFiles] = useDirectUploadFilesAppraisal();

    const [getBusinessDetails, { 
        loading: isBusinessDetailsLoading, 
        data: businessDetailsData,
    }] = useLazyQuery(GET_BUSINESS_DETAILS);

    const [getCreditAppraisalData, { 
        // loading: creditAppraisalLoading, 
        data: creditAppraisal,
    }] = useLazyQuery(GET_CREDIT_APPRAISAL);
    
    const [updateBusinessDetails] = useMutation(UPDATE_BUSINESS_DETAILS_CREDIT_APPRAISAL);

    const { update: updateIntercom } = useIntercom();

    const isOwnerSession = useCallback(() => (currentSessionRole === 'owner' || currentSessionRole === 'officer' || currentSessionRole === 'owner_officer'), [currentSessionRole]);

    const goToNextStep = useCallback((workflowSteps) => {
        const pageIDsToRouteMappings = {
            business_details: `${currentPath}/business-details`,
            personal_details: `${currentPath}/personal-details`,
            financial_documents: `${currentPath}/financial-documents`,
            thank_you: `${currentPath}/thank-you`,
        };
        if (workflowSteps) {
            const nextStep = workflowSteps.find((step) => step.status === 'pending');
            const id = get(nextStep, 'name');
            let route = pageIDsToRouteMappings.thank_you;
            if (id) route = pageIDsToRouteMappings[id];
            history.push(route);
        }
    }, [history, currentPath]);

    useEffect(() => {
        const user = get(creditAppraisal, 'session.user', null);
        if (user) {
            FullStory.identify(user.number, {
                company: formatCapital(user.company.name),    
                displayName: formatCapital(user.fullName),
                email: user.email,
            });
            updateIntercom({ 
                name: formatCapital(user.fullName),
                email: user.email,
                created_at: new Date(user.createdAt).valueOf(),
                customAttributes: {
                    application: 'customer-app',
                    environment: process.env.REACT_APP_NODE_ENV,
                    resourceId: user.id,
                    resourceType: 'User',
                },
            });
        }
    });
    
    useEffect(() => {
        FullStory.event(`Workflow Step Changed -> ${getPathID(pathname)}`);
    }, [pathname]);
    
    useEffect(() => {
        setWorkflow(get(creditAppraisal, 'session.creditAppraisal.applicationSteps'));
        flowSettersContext.setVendor(
            { 
                type: 'update_vendor',
                payload: get(creditAppraisal, 'session.user.company.seller', {
                    name: '', 
                    logoUrl: '',
                }),
            },
        );
        setCurrentSessionRole(get(creditAppraisal, 'session.user.role', 'other'));
    }, [creditAppraisal, flowSettersContext]);

    useEffect(() => {
        const steps = workflow?.filter((element) => element.status === 'pending').map((element) => element.name);
        setSessionSteps((s) => union(s, steps));
        goToNextStep(workflow);
    }, [workflow, goToNextStep]);

    const onUpdateFirstOpenedAt = useCallback((path) => {
        try {
            updateFirstOpenedAt({ 
                variables: {
                    currentStep: path,
                },
            });
        } catch (e) {
            console.log(e);
        }
    }, [updateFirstOpenedAt]);


    useEffect(() => {
        let path = '';
        dispatchStepInfo({ type: getPathID(pathname) });
        getCreditAppraisalData();
        if (pathname.includes('personal-details')) {
            path = 'personal_details';
            getContact();
        }
        if (pathname.includes('business-details')) {
            path = 'business_details';
            getBusinessDetails();
        }
        if (pathname.includes('financial-documents')) {
            path = 'financial_documents';
        }
        if (path !== '') {
            onUpdateFirstOpenedAt(path);
        }
    }, [
        pathname,
        getContact, 
        getBusinessDetails, 
        getCreditAppraisalData,
        onUpdateFirstOpenedAt,
    ]);

    useEffect(() => {
        let className = '';
        if (flowGettersContext.showOrderSummary) {
            className = 'lg:tw-min-w-50vw lg:tw-max-w-55vw sm:tw-min-w-80vw sm:tw-max-w-80vw tw-w-full';
        } else if (stepInfo.id === 'agreement') {
            className = 'sm:tw-w-60vw sm:tw-h-90vh tw-w-full';
        } else {
            className = 'sm:tw-min-w-60vw sm:tw-max-w-60vw lg:tw-min-w-35vw lg:tw-max-w-35vw tw-w-full';
        }
        flowSettersContext.setDimensionClass(className);
    }, [flowGettersContext, flowSettersContext, stepInfo.id]);

    useEffect(() => {
        if (stepInfo.id !== 'thank_you') {
            flowSettersContext.setStepTitle(stepInfo.title);
            flowSettersContext.setStepBodyClass('tw-mt-4');
        } else {
            flowSettersContext.setStepTitle('');
            flowSettersContext.setStepBodyClass('');
        }
    }, [flowSettersContext, stepInfo.title, stepInfo.id]);

    useEffect(() => {
        let pills;

        if (stepInfo.id === 'thank_you') {
            pills = [];
        } else {
            pills = sessionSteps.map((sstep) => {
                const step = workflow && workflow.filter((elem) => elem.name === sstep)[0];
                if (!step) return null;
    
                let icon = step.name === stepInfo.id ? bluePoint : greyPoint;
                if (step.status === 'complete') {
                    icon = blueTick;
                }
                return step.status === 'locked' ? null : <img className="tw-w-5 tw-pl-2" src={icon} alt="" key={step.name} />;
            });
        }
        
        flowSettersContext.setStepPills(pills);
    }, [sessionSteps, workflow, stepInfo, flowSettersContext]);


    async function onPersonalDetailsFormSubmit(values, { setErrors }) {
        try {
            const responseData = await updateContact({ 
                variables: {
                    ...values,
                    ...values.defaultAddress,
                },
            });
            setErrors(get(responseData, 'errors', {}));
            setWorkflow(get(responseData, 'data.updatePersonalDetailsCreditAppraisal.creditAppraisal.applicationSteps'));
            setCurrentSessionRole(get(responseData, 'data.updatePersonalDetailsCreditAppraisal.user.role'));
        } catch (e) {
            console.log(e);
        }
    }

    async function onBusinessDetailsFormSubmit(values, { setErrors }) {
        try {
            const responseData = await updateBusinessDetails({ 
                variables: {
                    ...values,
                },
            });
            setErrors(get(responseData, 'errors', {}));
            setWorkflow(get(responseData, 'data.updateBusinessDetailsCreditAppraisal.creditAppraisal.applicationSteps'));
        } catch (e) {
            console.log(e);
        }
    }

    async function onFinancialDocumentsSubmit(values) {
        try {
            await directUploadFiles([...values.financials], {
                documentType: 'financial',
                companyId: get(creditAppraisal, 'session.user.company.id', null),
                creditAppraisalId: get(creditAppraisal, 'session.creditAppraisal.id', null),
            });

            const responseData = await updateCreditAppraisalApplicationSteps();
            setWorkflow(get(responseData, 'data.updateCreditAppraisalApplicationSteps.creditAppraisal.applicationSteps'));
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <>
            { pathname.includes('personal-details') && <p className="tw-text-sm">We may need to collect a personal guaranty as part of a future order.</p> }
            <div className="tw-mt-3">
                <Switch>
                    <Route 
                        path={`${currentPath}/personal-details`}
                        exact
                        render={() => (
                            <PersonalDetails
                                isFormLoading={isPersonalDetailsLoading}
                                personalDetailsData={get(personalDetail, 'session.user')}
                                onFormSubmit={onPersonalDetailsFormSubmit}
                                button={({ isFormLoading, isSubmitting }) => (
                                    <div className="tw-flex tw-flex-col sm:tw-flex-row-reverse tw-justify-center sm:tw-justify-start tw-mt-4">
                                        <Button
                                            className="tw-bg-blue"
                                            label="Next" 
                                            type="submit"
                                            disabled={isFormLoading || isSubmitting} 
                                        />
                                    </div>
                                )}
                            />
                        )} 
                    />
                    <Route 
                        path={`${currentPath}/business-details`}
                        exact
                        render={() => (
                            <BusinessDetails
                                isFormLoading={isBusinessDetailsLoading}
                                businessDetailsData={{
                                    ...get(businessDetailsData, 'session.user.company'),
                                    employeeCount: null,
                                    annualRevenue: null,
                                }}
                                onFormSubmit={onBusinessDetailsFormSubmit}
                                button={({ isFormLoading, isSubmitting }) => (
                                    <div className="tw-flex tw-flex-col sm:tw-flex-row-reverse tw-justify-center sm:tw-justify-start tw-mt-4">
                                        <Button
                                            className="tw-bg-blue"
                                            label="Next" 
                                            type="submit"
                                            disabled={isFormLoading || isSubmitting} 
                                        />
                                    </div>
                                )}
                            />
                        )} 
                    />
                    <Route 
                        path={`${currentPath}/financial-documents`}
                        exact
                        render={() => (
                            <FinancialDocuments
                                onFormSubmit={onFinancialDocumentsSubmit}
                                button={({ isFormLoading, isSubmitting, values }) => (
                                    <div className="tw-flex tw-flex-col sm:tw-flex-row-reverse tw-justify-center sm:tw-justify-start tw-mt-4">
                                        <Button
                                            className="tw-bg-blue"
                                            label="Next" 
                                            type="submit"
                                            disabled={isFormLoading || isSubmitting || !values.financials.length} 
                                        />
                                    </div>
                                )}
                            />
                        )}
                    />
                    <Route 
                        path={`${currentPath}/thank-you`}
                        exact
                        render={() => (
                            <ThankYou />
                        )}
                    />
                    <Redirect to={`${currentPath}`} />
                </Switch>
            </div>


            <div className="tw-block">
                <div className="tw-mt-3 tw-mb-3">
                    <Divider />
                </div>
                <div className="tw-flex tw-flex-row-reverse tw-justify-start tw-mt-6">
                    <div className="tw-w-44">
                        <img className="tw-w-full tw-h-full" src={poweredBy} alt="Powered by Vartana" />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Appraisal;
