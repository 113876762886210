import React from 'react';
import PropTypes from 'prop-types';


function Button({ 
    label, 
    disabled, 
    type, 
    onClick,
    className,
    labelClass,
    paddingClass,
    borderClass,
    textClass,
}) {
    return (
        <button 
            className={`${disabled ? 'tw-cursor-not-allowed' : ''} tw-primary-btn focus:tw-outline-none disabled:tw-opacity-50 ${borderClass} ${paddingClass} ${className}`}
            type={type}
            disabled={disabled}
            onClick={onClick}
        >
            <p className={`inline-block ${labelClass} ${textClass}`}>
                {label}
            </p>
        </button>
    );
}


Button.propTypes = {
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    labelClass: PropTypes.string,
    paddingClass: PropTypes.string,
    borderClass: PropTypes.string,
    textClass: PropTypes.string,
};

Button.defaultProps = {
    disabled: false,
    type: '',
    onClick: () => {},
    className: '',
    labelClass: 'tw-text-white',
    paddingClass: 'tw-pt-3 tw-pb-3 tw-pl-7 tw-pr-7',
    borderClass: 'tw-border-solid tw-rounded-lg',
    textClass: 'tw-font-semibold tw-text-sm',
};

export default Button;
