import { gql } from '@apollo/client';

// todo - make sure returns the primary user
export const UPSERT_PAYMENT_METHOD = gql`
    mutation(
        $id: ID,
        $paymentMode: String!,
        $bank: String,
        $accountName: String,
        $accountType: String,
        $routingNumber: String,
        $accountNumber: String,
        $contactName: String,
        $phone: String,
        $email: String,
        $street: String,
        $suite: String,
        $city: String,
        $state: String,
        $zip: String,
        $country: String,
        $validateOnly: Boolean
        $accountId: String,
        $accessToken: String,
        $itemId: String,
        $requestId: String,
    ) {
        upsertPaymentMethod(
            id: $id
            paymentMode: $paymentMode
            bank: $bank
            accountName: $accountName
            accountType: $accountType
            routingNumber: $routingNumber
            accountNumber: $accountNumber
            contactName: $contactName
            phone: $phone
            email: $email
            street: $street
            suite: $suite
            city: $city
            state: $state
            zip: $zip
            country: $country
            validateOnly: $validateOnly
            accountId: $accountId
            accessToken: $accessToken
            itemId: $itemId
            requestId: $requestId
        ) {
            id
            order {
                id
            }
        }
    }
`;

export const GET_DEFAULT_PAYMENT_METHOD = gql`
    query {
        session {
            id
            user {
                company {
                    id
                    name
                    defaultPaymentMethod {
                        id
                        isDefault
                        paymentMode
                        bank
                        accountName
                        accountType
                        routingNumber
                        accountNumber
                        contactName
                        phone
                        email
                        street
                        suite
                        city
                        state
                        zip
                        country
                        logo
                    }
                }
            }
            order {
                invoiceEnabled
            }
        }
    }
`;
