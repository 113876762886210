import React, { 
    useMemo, useEffect, useState, useReducer,
} from 'react';
import { 
    Route, 
    Switch, 
    Redirect,
    useLocation, 
    useRouteMatch,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Divider from '../../components/Divider';

import Appraisal from '../Appraisal';
import Checkout from '../Checkout';

import AutoLoad from '../../components/AutoLoad';

import { FlowSettersContext, FlowGettersContext } from '../../utils/contexts';
import { getAPIURL, formatCapital } from '../../utils/helpers';

const VENDOR_INITIAL_STATE = {
    name: '', 
    logoUrl: '',
};

function vendorReducer(
    state = VENDOR_INITIAL_STATE,
    action = { type: '', payload: VENDOR_INITIAL_STATE },
) {
    switch (action.type) {
    case 'update_vendor':
        return {
            ...state,
            name: action?.payload?.name || '',
            logoUrl: action?.payload?.logoUrl || '',
        };
    default:
        return VENDOR_INITIAL_STATE;
    }
}

function Flow() {
    const [vendor, setVendor] = useReducer(vendorReducer, VENDOR_INITIAL_STATE);
    const [showOrderSummary, setShowOrderSummary] = useState(false);
    const [showNetTermsSummary, setShowNetTermsSummary] = useState(false);
    const [dimensionClass, setDimensionClass] = useState('');
    const [stepBodyClass, setStepBodyClass] = useState('');
    const [showPills, setShowPills] = useState(true);
    const [stepPills, setStepPills] = useState([]);
    const [stepTitle, setStepTitle] = useState('');

    const [isLoading, setisLoading] = useState(true);

    const location = useLocation();
    const { path: currentPath } = useRouteMatch();

    useEffect(() => {
        const useQueryParams = new URLSearchParams(location.search);
        const token = useQueryParams.get('t');
        if (token !== null) {
            fetch(`${getAPIURL()}/set-cookie`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    token,
                    app: 'customer-app',
                }),
                credentials: 'include',
            }).then((response) => response.json())
                .then((result) => {
                    setisLoading(!result.success);
                });
        } else {
            setisLoading(false);
        }
    }, [location.search]);


    useEffect(() => {
        if (!vendor) return;

        const image = new Image();

        image.onload = () => {
            let link = document.getElementById('vendor_favicon');
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                link.id = 'vendor_favicon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = vendor.faviconUrl;
        };
            
        image.src = vendor.faviconUrl;
    }, [vendor]);

    const renderStepTitle = () => {
        const title = stepTitle || '';

        return (
            <div className="step-title tw-justify-center sm:tw-justify-start tw-flex">
                <h5 className="tw-font-bold">{title}</h5>
            </div>
        );
    };

    const renderPills = () => {
        const pills = (stepPills?.length === 0 || !showPills) ? null : stepPills;

        return (
            <div className="step-progress tw-flex tw-flex-row sm:tw-justify-end sm:tw-p-0 tw-justify-center tw-pt-3 tw-pb-3">
                {pills}
            </div>
        );
    };

    const flowGettersContext = useMemo(() => ({
        showOrderSummary,
        showNetTermsSummary,
    }), [showOrderSummary, showNetTermsSummary]);

    const flowSettersContext = useMemo(() => ({ // needs to be constant across rerenders
        setShowOrderSummary,
        setShowNetTermsSummary,
        setDimensionClass,
        setStepBodyClass,
        setShowPills,
        setStepPills,
        setStepTitle,
        setVendor,
    }), []);

    const renderedTitle = renderStepTitle();
    const renderedPills = renderPills();

    return (
        <AutoLoad loading={isLoading}>
            <FlowSettersContext.Provider value={flowSettersContext}>
                <FlowGettersContext.Provider value={flowGettersContext}>
                    <Helmet>
                        <title>{formatCapital(vendor.name)}</title>
                    </Helmet>
                    <div className="tw-font-sans tw-min-h-screen tw-bg-white sm:tw-bg-main-bg">
                        <div className="tw-min-h-screen tw-flex tw-flex-row tw-justify-center tw-items-center">
                            <div className={`tw-bg-white tw-flex tw-flex-col ${dimensionClass} tw-border-solid tw-rounded sm:tw-border tw-border-grey-1 tw-p-7`}>
                                
                                <div className="logo tw-mx-auto tw-mb-2 sm:tw-m-0">
                                    <img className="tw-max-h-8" src={vendor.logoUrl} alt={vendor.name}></img>
                                </div>
                    
                                <div className="tw-mt-3 tw-mb-3">
                                    <Divider />
                                </div>

                                <div className={`${showOrderSummary ? 'sm:tw-flex sm:tw-divide-x sm:tw-divide-grey-3 sm:tw-mt-3' : 'tw-mt-3'}`}>
                                    <div className={`${showOrderSummary ? 'tw-pr-0 sm:tw-pr-6 sm:tw-flex-3' : ''}`}>
                                        <div className="form-step tw-hidden sm:tw-flex sm:tw-flex-row sm:tw-justify-between tw-flex-col">
                                            {renderedTitle}
                                            {renderedPills}
                                        </div>
                                        <div className="form-step tw-flex tw-flex-col sm:tw-hidden">
                                            {renderedPills}
                                            {renderedTitle}
                                        </div>
                                        <div className={`${stepBodyClass} tw-h-55vh tw-overflow-y-auto sm:tw-h-auto sm:tw-overflow-y-none`}>
                                            <Switch>
                                                <Route
                                                    path="/customer"
                                                    render={() => (
                                                        <Switch>
                                                            <Route 
                                                                path={`${currentPath}/appraisal`} 
                                                                component={Appraisal} 
                                                            />
                                                            <Redirect to="/" />
                                                        </Switch>
                                                    )}
                                                />
                                                <Route
                                                    path="/order"
                                                    render={() => (
                                                        <Switch>
                                                            <Route
                                                                path={`${currentPath}/checkout`}
                                                                component={Checkout}
                                                            />
                                                            <Redirect to="/" />
                                                        </Switch>
                                                    )}
                                                />
                                                <Redirect to="/" />
                                            </Switch>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </FlowGettersContext.Provider>
            </FlowSettersContext.Provider>
        </AutoLoad>
    );
}

export default Flow;
