import { 
    ApolloClient,
    InMemoryCache, 
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';

import { mapGraphqlErrorsToObject, getAPIURL } from '../utils/helpers';
import history from '../utils/browser_history';

const extensions = {
    SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
    VALIDATION_ERROR: 'VALIDATION_ERROR',
    AUTHENTICATION_ERROR: 'AUTHENTICATION_ERROR',
    THANK_YOU: 'THANK_YOU',
};

const onErrorLink = onError(({
    response, 
    graphQLErrors, 
    networkError, 
}) => {
    if (graphQLErrors) {
        graphQLErrors.forEach((err) => {
            switch (err?.extensions?.code) {
            case extensions.VALIDATION_ERROR: {
                const parsedErrors = JSON.parse(err.message);
                response.errors = mapGraphqlErrorsToObject(parsedErrors);
                break;
            }
            case extensions.AUTHENTICATION_ERROR: {
                if (!history.location.pathname.includes('thank-you')) {
                    history.push({
                        pathname: '/error',
                        state: {
                            errorTitle: 'Unauthorized', 
                            errorMsg: 'Not allowed',
                        },
                    });
                }
                break;
            }
            case extensions.SERVER_ERROR: {
                history.push({
                    pathname: '/error',
                });
                break;
            }
            case extensions.THANK_YOU: {
                const body = JSON.parse(err.message);
                history.push({
                    pathname: '/order/checkout/thank-you',
                    state: {
                        title: body.title,
                        message: body.message,
                    },
                });
                break;
            }
            default:
            }
        });
    }
    if (networkError) {
        console.error(`[Network error]: ${networkError}`);
    }
});

const httpLink = createUploadLink({
    uri: `${getAPIURL()}/graphql`,
    credentials: 'include',
});

const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
};

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: onErrorLink.concat(httpLink),
    defaultOptions,
});

export default client;
