import React from 'react';
import PropTypes from 'prop-types';
import Rollbar from 'rollbar';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            rollbar: new Rollbar({
                enabled: (process.env.REACT_APP_NODE_ENV === 'production' || process.env.REACT_APP_NODE_ENV === 'staging' || process.env.REACT_APP_NODE_ENV === 'preview'),
                accessToken: process.env.REACT_APP_ROLLBAR_CLIENT_ITEM_ACCESS_TOKEN,
                captureUncaught: true,
                captureUnhandledRejections: true,
                payload: {
                    environment: process.env.REACT_APP_NODE_ENV,
                    client: {
                        javascript: {
                            source_map_enabled: true,
                            code_version: process.env.REACT_APP_GIT_SHA,
                        },
                    },
                },
            }),
        };

        this.logError = this.logError.bind(this);
    }
 
    static getDerivedStateFromError(error) {
        console.error('Error:', error);
        return { hasError: true };
    }
    
    componentDidCatch(error, errorInfo) {
        console.error('Error Info:', errorInfo);
        this.logError(error);
    }

    logError(error) {
        const { rollbar } = this.state;
        rollbar.error(error);
    }
  
    render() {
        const { hasError } = this.state;
        const { children } = this.props;
        
        if (hasError) {
            // TODO: implement proper error page
            return <h1>Something went wrong.</h1>;
        }
        return children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default ErrorBoundary;
