import { gql } from '@apollo/client';

// todo - make sure returns the primary user

export const GET_CONTACT_VERIFICATION_STATUS = gql`
    query {
        session {
            id
            user {
                id
                personaInquiryId
                verifiedAt
                verified
                email
                phone
            }
        }
    }
`;

export const GET_CONTACT = gql`
    query {
        session {
            id
            user {
                id
                firstName
                lastName
                isOwner
                email
                phone
                defaultAddress {
                    state
                    city
                    zip
                    street
                    suite
                }
            }
        }
    }
`;

export const CREATE_CONTACTS = gql`
    mutation($owners: [OwnerInput!]!) {
        createOwners(owners: $owners) {
            id
            user {
                id
            }
            order {
                id
            }
        }
    }
`;

export const DESTROY_CONTACT = gql`
    mutation($id: ID!) {
        destroyOwner(
            id: $id
        ) {
            id
        }
    }
`;


export const UPDATE_CONTACT = gql`
    mutation(
        $firstName: String!, 
        $lastName: String!,
        $email: String!,
        $isOwner: Boolean!,
        $phone: String!,
    ) {
        updatePersonalDetails(
            firstName: $firstName
            lastName: $lastName
            email: $email
            isOwner: $isOwner
            phone: $phone
        ) {
            id
            user {
                id
                role
            }
            order {
                id
            }
        }
    }
`;

export const UPDATE_CONTACT_CREDIT_APPRAISAL = gql`
    mutation(
        $firstName: String!, 
        $lastName: String!,
        $email: String!,
        $isOwner: Boolean!,
        $phone: String!,
        $state: String,
        $city: String,
        $zip: String,
        $street: String,
        $suite: String
    ) {
        updatePersonalDetailsCreditAppraisal(
            firstName: $firstName
            lastName: $lastName
            email: $email
            isOwner: $isOwner
            phone: $phone
            state: $state
            city: $city
            zip: $zip
            street: $street
            suite: $suite
        ) {
            id
            user {
                id
                role
            }
            creditAppraisal {
                id
                number
                applicationSteps
            }
        }
    }
`;

export const UPDATE_PERSONAL_GUARANTEE = gql`
    mutation (
        $agreed: Boolean!,
        $ssn: String!,
        $dob: ISO8601Date!
        $street: String!,
        $suite: String,
        $city: String!,
        $state: String!,
        $zip: String!,

    ) {
        updatePersonalGuarantee (
            agreed: $agreed
            ssn: $ssn
            dob: $dob
            street: $street,
            suite: $suite,
            city: $city,
            state: $state,
            zip: $zip,
        ) {
            id
            order {
                id
            }
        }
    }
`;

export const SEND_APPLICATION = gql`
    mutation (
        $firstName: String!, 
        $lastName: String!,
        $email: String!,
        $phone: String!,
    ) {
        sendApplication (
            firstName: $firstName
            lastName: $lastName
            email: $email
            phone: $phone
        ) {
            order {
                id
            }
        }
    }
`;

export const UPDATE_CONTACT_VERIFICATION_STATUS = gql`
    mutation {
        updateContactVerificationStatus {
            order {
                id
            }
        }
    }
`;
